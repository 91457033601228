.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #16b724;
  height: 94%;

  @media (min-width: 300px) {
    padding: 30px;
  }

  @media (min-width: 1024px) {
    padding-left: 120px;
  }
}

.text {
  max-width: 700px;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
}

.buoy {
  width: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between
}
