.text {
  color: white;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.root {
  justify-content: space-around;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
  align-content: center;
}

.container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 30px;
}

.itemContainer {
  // make in 4 cols
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 21%;

  @media (min-width: 360px) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 42%;
  }

  @media (min-width: 400px) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 41%;
  }
  
  @media (min-width: 640px) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 21%;
  }
  
}

.iconContainer {
  width: 75px;
  height: 75px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid #4dc247;
  transition: all 0.5s ease;

  .icon {
    color: #4dc247;
    margin-top: 20px;
    font-size: 2em;
    transition: all 0.5s ease;
  }

}

.iconContainer:hover {
  border: 3px solid #4dc247;
  box-shadow: 0 0 15px #449b43;
  transition: all 0.5s ease;

  .icon {
    color: #4dc247;
    filter: drop-shadow(0px 3px 3px rgb(56, 148, 53));
    transition: all 0.5s ease;
  }
}

